@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Hahmlet:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");



.signupform input {
  color: #232323;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-radius: 10px;
  border: 1.5px solid #AEAEAE;
}

.signupform label {
  color: #9A9A9A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.font-feature-custom {
  font-feature-settings: "clig"off, "liga"off;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  background-color: #F6F7F9;
}

.font-hahmlet {
  font-family: "Hahmlet" !important;
}

@media screen and (max-width:600px) {
  .signUpFormMail {
    width: 100%;
    padding: 30px;
  }

  .tellUsWhat {
    padding: 15px;
  }

  .closeBtnBox {
    right: 30px;
  }
}