.aboutUsBtn {
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 28px;
    border: 1px solid #227962;
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.aboutUsBtn a {
    font-family: "Roboto" !important;
}